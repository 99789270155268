var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',[_c('v-row',{class:_vm.fullWidth ? 'pt-5' : 'pt-15',attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.fullWidth ? 12 : 10}},[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tableTitle)+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"tile":"","color":"primary","to":{name: _vm.routeName},"disabled":_vm.disableBtnCreate}},[_vm._v("+ "+_vm._s(_vm.newButtonLabel))]),(_vm.showDownloadBtn)?_c('v-btn',{staticClass:"mt-3 ml-1",attrs:{"loading":_vm.loadingDownload,"title":_vm.downloadTitle,"icon":"","tile":"","color":"primary"},on:{"click":_vm.emitDownload}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espera por favor","no-data-text":"Aún no hay registros","no-results-text":"No se encontraron coincidencias","footer-props":{
              itemsPerPageText: 'Elementos por página'
            }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [(item.groupId === null)?_c('v-img',{attrs:{"src":require(("../../assets/images/" + (item.avatar))),"width":"30"}}):_c('v-avatar',{attrs:{"size":"38"}},[_c('v-img',{attrs:{"src":item.avatar}})],1)]}},{key:"item.foto",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"38"}},[_c('v-img',{attrs:{"src":item.foto}})],1)]}},{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('kn-menu',{attrs:{"items":_vm.menuOptions(item)}})]}}],null,true)})],1)],1)],1),(_vm.showBottomAction)?_c('v-row',{staticClass:"pt-0 mt-n16",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.fullWidth ? 12 : 10}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.emitShowAction}},[_c('span',{staticClass:"text-capitalize mr-2"},[_vm._v("Ver "+_vm._s(_vm.tableTitle)+" inactivos")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }