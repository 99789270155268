<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    persistent
    max-width="390"
  >
    <v-card
      tile
      flat
      :class="borderColor"
    >
      <div
        v-if="loadingAlert"
        class="progressContainer text-center px-5"
      >
        <p class="graydefault--text font-weight-medium">{{ alertText }}</p>
        <v-progress-circular
          indeterminate
          :color="progressColor"
        />
      </div>
      <v-card-text
        v-if="!loadingAlert"
        class="pt-15 text-h5 font-weight-bold text-center"
        :class="textColor"
      >
        {{ alertText }}
      </v-card-text>
      <v-card-actions
        v-if="!loadingAlert"
        class="pb-5">
        <v-btn
          v-for="btn in buttons"
          :key="btn.text"
          :class="btn.style"
          :color="btn.color"
          outlined
          tile
          @click="btn.action"
        >
          {{ btn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    alertType: {
      type: String,
      default: 'info'
    },
    alertText: {
      type: String,
      default: 'Por favor, espere'
    },
    alertColor: {
      type: String,
      default: null
    },
    loadingAlert: {
      type: Boolean,
      default: false
    },
    textBtn2: {
      type: String,
      default: null
    }
  },
  computed: {
    borderColor() {
      if (this.alertColor !== null) {
        return `${this.alertColor}_style`
      } else {
        switch(this.alertType) {
          case 'success': return 'success_style'
          case 'error': return 'error_style'
          case 'info': return 'info_style'
           case 'warning': return 'warning_style'
          default: return 'info_style'
        }
      }
    },
    textColor() {
      if (this.alertColor !== null) {
        return `${this.alertColor}--text`
      } else {
        switch(this.alertType) {
          case 'success': return 'success--text'
          case 'error': return 'error--text'
          case 'info': return 'info--text'
          case 'warning': return 'warning--text'
          default: return 'info--text'
        }
      }
      
    },
    progressColor() {
      if (this.alertColor !== null) {
        return this.alertColor
      } else {
        switch(this.alertType) {
          case 'success': return 'success'
          case 'error': return 'error'
          case 'info': return 'info'
          case 'warning': return 'warning'
          default: return 'info'
        } 
      }
    },
    buttons() {
      switch(this.alertType) {
        case 'success': return [
          { 
            text: 'Cerrar',
            action: () => this.action1(),
            style: 'mr-auto', 
            color: ''
          },
          {
            text: this.textBtn2 ? this.textBtn2 : 'Agregar otro',
            action: () => this.action2(),
            style: '',
            color: this.alertColor ? this.alertColor : 'success'
          },
        ]
        default: return [
          {
            text: 'Cerrar',
            action: () => this.action1(),
            style: 'mx-auto',
            color: this.progressColor
          }
        ]
      }
    }
  },
  methods: {
    action1() {
      this.$emit('action1')
    },
    action2() {
      this.$emit('action2')
    }
  }
}
</script>
<style>
.v-card.success_style{
  border-color: #00AE42 !important;
  border-style: solid !important;
  border-width: medium !important;
}
.v-card.error_style{
  border-color: #FF5F00 !important;
  border-style: solid !important;
  border-width: medium !important;
}
.v-card.info_style{
  border-color: #136FEE !important;
  border-style: solid !important;
  border-width: medium !important;
}
.v-card.warning_style{
  border-color: #FFB600 !important;
  border-style: solid !important;
  border-width: medium !important;
}
.progressContainer {
  min-height: 100px;
  margin-top: 30px;
}
</style>