<template>
  <section>
    <kn-table 
      :headers="headers" 
      :items="items"
      :loading="loading"
      :showDownloadBtn="true"
      tableTitle="Resumen de egresos" 
      newButtonLabel="Agregar egresos"
      downloadTitle="Descargar reporte de egresos"
      @action="openAlert"
      @downloadAction="downloadExcelExpense"
    />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loadingAlert"
      textBtn2="Aceptar"
      @action1="actionAlertBtn1"
      @action2="actionAlertBtn2"
    />
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { apiMixin } from '@/mixins/apiMixin'
import { api } from '@/api/axios-base'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import KnTable from '@/components/tables/KnTable.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
export default {
  components: { KnTable, KnLocalAlert },
  mixins: [apiMixin, fileUtilsMixin],
  data() {
    return {
     headers: [
        { text: 'Fecha', value: 'fecha', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'subtotal', class: 'purple--text' },
        { text: 'Descuento', value: 'descuento', class: 'purple--text' },
        { text: 'Total', value: 'total', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      search: null,
      loading: true,
      itemToEdit: null,
      loadingDownload: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Estas a punto de "Corregir" un egreso. Deseas continuar?',
      alertColor: 'error',
      /*********************** */
    }
  },
  computed: {
    ...mapState(['institutionId'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    await this.fetchExpenses(this.institutionId)
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchExpenses(institutionId) {
      try {
        const response = await api.get(`/administracion/api/egreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        // console.log('Respuesta egresos: ', responseData);
        const expenses = responseData
        expenses.forEach(expense => {
          const concept = `${expense.categoria.dato} - ${expense.sub_categoria.dato}`
          const item = {
            folio: expense.id,
            fecha: expense.fecha_elaboracion.substring(0, 10),
            concepto: concept,
            subtotal: expense.sub_total,
            descuento: expense.total_descuento,
            total: expense.total_egreso,
            ...expense
          }
          this.items.push(item)
        });
      } catch (error) {
        console.error('Error al obtener egresos.', error);
      }
    },
    openAlert(obj) {
      // console.log('Open modal', obj);
      this.itemToEdit = this.items.find(i => i.id === obj.itemId)
      this.showAlert = true
    },
    actionAlertBtn1() {
      this.closeAlert()
    },
    actionAlertBtn2() {
      this.showAlert = false
      this.$router.push({
        name: 'Editar Egresos',
        params: {
          entity: this.itemToEdit,
          readonly: false
        }
      })
    },
    closeAlert() {
      this.itemToEdit = null
      this.showAlert = false
    },
    async downloadExcelExpense () {
      try {
        this.loadingDownload = true
        const fileName = 'egresos.xls'
        const response = await api.get('/administracion/reporte-egresos', {
          responseType: 'blob'
        })
        const responseData = await response.data
        console.log("Blob?", responseData);
        if (response.status === 200) {
          this.downloadFileFromBlob(responseData, fileName)
        }
      } catch (error) {
        console.error("Ocurrión un error al intentar descargar el reporte de egresos")
      } finally {
        this.loadingDownload = false
      }
    }
  }
}
</script>

<style>
.border {
  border-color: gray;
  border-style: solid;
  border-width: 1px;
}
</style>